








































































































import { BaseItemViewAction } from '@/includes/types/BaseItemViewAction'
import { Form } from '@/includes/types/Form.types'
import FormAnswers from '@/components/Forms/FormAnswers.vue'
import FormPreview from '@/components/Forms/components/FormPreview.vue'
import FormsItemsList from '@/components/Forms/components/FormsItemsList.vue'

import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'
import { FieldData } from 'piramis-base-components/src/components/Pi/types'
import { UseFields } from 'piramis-base-components/src/components/Pi'
import DrawerWidthMixin from 'piramis-base-components/src/logic/helpers/DrawerWidthMixin'

import { Component, Mixins } from 'vue-property-decorator'
import moment from "moment";

enum SidebarType {
  Preview,
  Answers,
}

enum SortBy {
  Date,
  Answers,
}

@Component({
  components: {
    FormPreview,
    PageTitle,
    FormAnswers,
    EmptyData,
    FormsItemsList,
  },
  data() {
    return {
      BaseItemViewAction,
      SidebarType,
      SortBy,
    }
  }
})
export default class FormList extends Mixins(UseFields, DrawerWidthMixin) {
  sidebarIsOpen = false

  sidebarTitle = this.$t('form_answer_list').toString()

  sidebarType = SidebarType.Answers

  selectedForm: null | Required<Form> = null

  sortBy: SortBy = SortBy.Answers

  get forms() {
    return this.$store.state.formsState.forms
  }

  get activeForms(): Array<Required<Form>> {
    if (this.forms) {
      return this.forms
        .filter((f) => f.answers > 0)
        .sort(this.sortBy === SortBy.Date ? this.sortByCreatingDate : this.sortFormsByAnswers)
    }

    return []
  }

  sortByCreatingDate(a: Required<Form>, b: Required<Form>): number {
    if (a.updated && !b.updated) {
      return moment(a.updated).isAfter(moment(b.created)) ? 1 : -1
    }

    if (!a.updated && b.updated) {
      return moment(a.created).isAfter(moment(b.updated)) ? -1 : 1
    }

    if (a.updated && b.updated) {
      return moment(a.updated).isAfter(moment(b.updated)) ? 1 : -1
    }

    return moment(a.created).isAfter(moment(b.created)) ? 1 : -1
  }

  sortFormsByAnswers(a: Required<Form>, b: Required<Form>): number {
    if (a.answers > b.answers) {
      return -1
    }

    if (a.answers < b.answers) {
      return 1
    }

    return 0
  }

  setSortBy(arg: FieldData): FieldData {
    arg.setter = (val) => this.sortBy = val
    return arg
  }

  get notActiveForms(): Array<Required<Form>> {
    if (this.forms) {
      return this.forms
        .filter((f) => !f.answers)
        .sort(this.sortByCreatingDate)
    }

    return []
  }

  get sortedForms(): Array<Required<Form>> {
    if (this.forms) {
      return this.forms.sort((a, b) => b.answers - a.answers)
    }

    return []
  }

  handleTagClick(formKey: Required<Form>): void {
    this.sidebarTitle = this.$t('form_answer_list').toString()
    this.selectedForm = formKey
    this.sidebarType = SidebarType.Answers

    this.sidebarIsOpen = true
  }

  showPreview(form: Required<Form>): void {
    this.sidebarTitle = this.$t('form_preview').toString()
    this.selectedForm = form
    this.sidebarType = SidebarType.Preview

    this.sidebarIsOpen = true
  }

  handleDrawerClose(): void {
    this.selectedForm = null

    this.sidebarIsOpen = false
  }

  gotoForm(actionType: BaseItemViewAction, formKey?: string): void {
    this.$router.push({
      name: 'Full_form',
      params: {
        actionType,
        ...formKey ? { formKey } : null,
      }
    })
  }

  async mounted(): Promise<void> {
    if (!this.$store.state.formsState.forms) {
      this.$baseTemplate.loader.open()

      await this.$store.dispatch('getForms')
      this.$baseTemplate.loader.close()
    }

    this.initializeSidebarWidth(800)
  }
}
